<template>
    <main class="cooking">
        <router-view
            class="cooking__data"
            :reportDaterange="reportDaterange"
            :compareDaterange="compareDaterange"
            :userRestaurants="userRestaurants"
            :serviceTypes="serviceTypes"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters } from "vuex";

    export default {
        name: "Cooking",
        data() {
            return {
                serviceTypes: [
                    { id: "total", title: "Все типы сервиса"},
                    { id: "delivery", title: "Доставка"},
                    { id: "pickup", title: "Самовынос"},
                    { id: "take", title: "Ресторан"},
                    { id: "hall", title: "С собой"},
                ]
            };
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                compareDaterange: state => state.cooking.compareDaterange,
            }),
            ...mapGetters([
                "userRestaurants"
            ])
        }
    }
</script>
